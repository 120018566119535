import React from "react";

import { Col, Row } from "react-grid-system";

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


function TeamDetails({
  teamHeadshot,
  teamImgAlt,
  teamName,
  designation,
  description,
  teamBtn,
  socialLink,
  ...props
}) {
  return (
    <Popup trigger={
      <div className="card-team__wrap"
        {...props}>
        <article className="card-team">
          {teamHeadshot ?
            (
              <div className="card-team__media">
                <img src={teamHeadshot} width={253} height={268} alt={teamImgAlt} />
              </div>
            ) : ""
          }
          <div className="info">
            {teamName ? (
              <h3>{teamName}</h3>
            ) : ""}
            {designation ? (
              <h4>{designation}</h4>
            ) : ""}
          </div>
          <div className="btn-wrap">
            {teamBtn ? (
              <span className="btn btn-secondary">{teamBtn}</span>
            ) : ""}
          </div>
        </article>
      </div>
    } modal>
      {close => (
        <div className="modal card-team__modal">
          <div className="modal-header text-right">
            <button className="close" onClick={close}>
              &times;
            </button>
          </div>
          <div
            {...props} className="modal-body">
            <Row>
              <Col lg={6} className="modal-col">
                {teamName ? (
                  <h3>{teamName}</h3>
                ) : ""}
                {designation ? (
                  <h4>{designation}</h4>               
                ) : ""}
              </Col>
              <Col lg={6} className="modal-col">
                {description ? (
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                ) : ""}
              </Col>
            </Row>
          </div>
        </div>
      )}

    </Popup>
  );
}

export default TeamDetails;
