import React from "react";
import { Link } from "gatsby";

function ContentWrap({
    contentHeading,
    contentSubHeading,
    contentPara,
    linkText,
    linkUrl,
    linkTarget,
    className,
    linkClass,
    onClick,
    ...props
}) {
    return (
        <div
            {...props}
        >
            <div className={`content-wrap ${className ? className : ""}`}>
                {contentSubHeading ? (
                    <div dangerouslySetInnerHTML={{ __html: contentSubHeading }} />
                ) : ""}
                {contentHeading ? (
                    <div dangerouslySetInnerHTML={{ __html: contentHeading }} />
                ) : ""}
                {contentPara ? (
                    <div dangerouslySetInnerHTML={{ __html: contentPara }} />
                ) : ""}
                {linkText ? (
                    <a onClick={onClick} href={linkUrl} target={linkTarget ? linkTarget : "_self"} className={`btn ${linkClass ? linkClass : ""}`} >{linkText}</a>
                ) : ""}
            </div>
        </div>
    );
}


export default ContentWrap;